import React from 'react';
import { Container } from '@material-ui/core';
import FinanceVehicleCalculator from '../components/FinanceVehicleCalculate';
import { NavBar } from '../components/NavBar';

function IndexPage() {
  return (
    <>
      <Container maxWidth="lg">
        <NavBar />
        <h1>โปรแกรมคำนวณงวดรถออนไลน์</h1>
      </Container>

      <section>
        {typeof window !== `undefined` ? <FinanceVehicleCalculator /> : null}
      </section>
    </>
  );
}

export default IndexPage;
